import React, { Component } from "react";
import {AptHomeStyles} from "./AptHomeStyles"

export default class AptHomeRoot extends Component {
  render() {
    return (
      <><AptHomeStyles/></>
  );
  }
}
